
<template>
      <div class="time-content" v-if="day > 30">
        <span class="time">{{ timeStr }}</span><span class="desc"> 到期</span>
      </div>
      <div class="time-content" v-else-if="day > 0 && day <= 30">
        <span class="desc">还剩</span> <span class="over-time">{{ day }}</span><span class="desc"> 天到期</span>
      </div>
  <div class="time-content" v-else-if="day < 0">
    <span class="over-time">使用版本已到期</span>
      </div>
  <div class="time-content" v-else>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import moment from "moment";

const props = defineProps({
  time: {
    type: String,
    default: '',
    required: false
  }
})
const {time} = toRefs(props)
//判断date是否大于time，并且计算出多少天
const {proxy} = getCurrentInstance();

const day = computed(() => {
  if (!time.value) return 0
  const date = moment(new Date().setHours(0)).format('YYYY-MM-DD')
  //获取今天的开始时间
  return moment(time.value).diff(date, 'days')
})
const timeStr = computed(() => {
  return moment(time.value).format('YYYY-MM-DD')
})
</script>
<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: "memberTimeStr",
});
</script>
<style lang="scss" scoped>
  .time-content {
    font-size: 14px;

    .time {
      color: $shopFsColor;
      font-weight: bold;
    }

    .desc {
      color: $shopFsColor2;
    }

    .over-time {
      color: $mainColor;
      font-weight: bold;
    }
  }
</style>
